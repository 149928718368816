<template>
	<div :class="['slider', {'slider--collapse' : collapse}]" :id="id">
		<div class="slider__container" :style="'height: ' + height + ';'">
			<slot></slot>
		</div>
		<div v-if="type === 'nav'" class="slider__arrow arrow--prev" id="sliderPrev" @click="prevSlide()">
			<Icon icon="arrow_left" />
		</div>
		<div v-if="type === 'nav'" class="slider__arrow arrow--next" id="sliderNext" @click="nextSlide()">
			<Icon icon="arrow_right" />
		</div>
		<div v-if="type === 'dots'" class="slider__dots">
			<div v-for="(dot, index) in slides" :key="dot.index">
				<div :class="['dot', {'dot--active' : index === currentSlideIndex}]" @click="dotSlide(index)"></div>
			</div>
		</div>
		<div v-if="type === 'buttons'" class="slider__buttons">
			<div class="slider__button button--prev" @click="prevSlide()">
				<Heading size="6" margin="none" uppercase>Prev</Heading>
			</div>
			<div class="slider__button button--next" @click="nextSlide()">
				<Heading size="6" margin="none" uppercase>Next</Heading>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
		time: String,
		height: String,
		type: String,
		collapse: Boolean,
  },
  beforeMount () {
    this.id = this._uid
  },
	data(){
		return{
			slideIndex: 0,
			currentSlideIndex: 0,
			id: '',
			slides: [],
		}
	},
	mounted: function () {
		let i;
		this.slides = document.getElementById(this.id).querySelectorAll('.slide');
		for (i = 0; i < this.slides.length; i++) {
			this.slides[i].setAttribute('id' , 'slide-' + this.id + '-' + [i])
		}
		document.getElementById("slide-" + this.id + '-' + this.currentSlideIndex).style.left = 0;
		clearInterval(this.myInterval);
		this.myInterval = setInterval(this.nextSlide , this.time)
	},
	destroyed(){
		clearInterval(this.myInterval);
	},
	methods: {
		dotSlide: function(e) {
			if(e > this.currentSlideIndex){
				this.nextSlide()
			}else{
				this.prevSlide()
			}
		},
		prevSlide: function() {
			clearInterval(this.myInterval);
			this.myInterval = setInterval(this.nextSlide , this.time)

			var nextSlideIndex;
			if (this.currentSlideIndex === 0 ) {
				nextSlideIndex = this.slides.length - 1;
			} else {
				nextSlideIndex = this.currentSlideIndex - 1;
			}	
			
			document.getElementById("slide-" + this.id + '-' + nextSlideIndex).style.left = "-100%";
			document.getElementById("slide-" + this.id + '-'  + this.currentSlideIndex).style.left = 0;
			document.getElementById("slide-" + this.id + '-'  + nextSlideIndex).setAttribute("class", "slide__single slideInLeft");
			document.getElementById("slide-" + this.id + '-'  + this.currentSlideIndex).setAttribute("class", "slide__single slideOutRight");
			
			this.currentSlideIndex = nextSlideIndex;
		},
		nextSlide: function() {
			clearInterval(this.myInterval);
			this.myInterval = setInterval(this.nextSlide , this.time)
		
			var nextSlideIndex;
			if (this.currentSlideIndex === (this.slides.length - 1) ) {
				nextSlideIndex = 0;
			} else {
				nextSlideIndex = this.currentSlideIndex + 1;
			}	
			document.getElementById("slide-" + this.id + '-'  + nextSlideIndex).style.left = "100%";
			document.getElementById("slide-" + this.id + '-'  + this.currentSlideIndex).style.left = 0;
			document.getElementById("slide-" + this.id + '-'  + nextSlideIndex).setAttribute("class", "slide__single slideInRight");
			document.getElementById("slide-" + this.id + '-'  + this.currentSlideIndex).setAttribute("class", "slide__single slideOutLeft");
			
			this.currentSlideIndex = nextSlideIndex;
		}
	},
}

</script>

<style lang="scss" scoped>
.slider{
	position: relative;
	width: 100%;
}
.slider__container {
	overflow-x: hidden;
	position: relative;
	width: 100%;
}
.slider__arrow{
	position: absolute;
	top: 50%;
	margin-top: -20px;
	height:40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	border-radius: 50%;
	cursor: pointer;
	opacity: 0.0;
}
.slider:hover .slider__arrow{
	opacity: 1.0;
}
.arrow--prev {
	left:-20px;
}
.arrow--next {
	right:-20px;
}
.slider__dots{
	display: flex;
	justify-content: center;
}
.dot{
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background: #fff;
	margin-right: 5px;
	border: 2px solid #e7e7e7;
	box-shadow: inset 0 0 0 6px #fff;
}
.dot--active{
	background: var(--slider-dot-background);
}


.slider__buttons{
	width: 100%;
	border-top: var(--slider-buttons-border-top);
	display: flex;
	justify-content: space-between;
}
.slider__button{
	padding: var(--slider-buttons-padding);
	cursor: pointer;
}
.button--prev{
	width: var(--slider-button-prev-width);
	border-right: var(--slider-button-prev-border);
	text-align: center;
}
.button--next{
	width: var(--slider-button-next-width);
	text-align: center;
}
</style>

<!-- Used in : AR, ED -->